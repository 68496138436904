import React from "react";
import Axios from "axios";
import "antd/dist/antd.css";
import "./App.css";
import Authentication from "./components/Authentication";
import Header from "./components/Header";
import MainPage from "./components/MainPage";
const firebase = require("firebase");

interface AppProps {}
interface AppState {
  userObject: UserObject | null;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = { userObject: null };
  }

  componentDidMount() {
    const self = this;
    try {
      firebase.auth().onAuthStateChanged(function (user: any) {
        if (user) {
          self.addUserInfoToUserObject(user);
          self.setState({ userObject: user });
        } else {
          self.setState({ userObject: null });
        }
      });
    } catch (error) {
      self.setState({ userObject: null });
    }
  }

  signInSuccessful = (userObject: UserObject | null) => {
    this.addUserInfoToUserObject(userObject);
    this.setState({ userObject: userObject });
  };

  userSignOut = () => {
    this.setState({ userObject: null });
  };

  updateUserObject = (key: string, value: string) => {
    let userObject = this.state.userObject;
    //@ts-ignore
    userObject[key] = value;
    this.setState({ userObject: userObject });
  };

  addUserInfoToUserObject = async (userObject: any) => {
    if (userObject == null) {
      userObject = this.state.userObject;
    }
    if (userObject != null) {
      const userToken: string = await firebase.auth().currentUser.getIdToken();
      let userDBObject_raw: any;
      try {
        userDBObject_raw = await Axios.get(
          "https://us-central1-envimetric.cloudfunctions.net/getUserInfo_0_1_0?userToken=" +
            userToken
        );
      } catch {
        await Axios.get(
          "https://us-central1-envimetric.cloudfunctions.net/addNewUserToDatabase_0_1_0?email=" +
            userObject.email
        );
        userDBObject_raw = await Axios.get(
          "https://us-central1-envimetric.cloudfunctions.net/getUserInfo_0_1_0?userToken=" +
            userToken
        );
      }
      const userDBObject: any = userDBObject_raw.data;
      if (typeof userDBObject.evaluationsConducted_ML === "number") {
        userObject["evaluationsConducted_ML"] =
          userDBObject.evaluationsConducted_ML;
      } else {
        userObject["evaluationsConducted_ML"] = 0;
      }
      if (typeof userDBObject.evaluationsPurchased_ML === "number") {
        userObject["evaluationsPurchased_ML"] =
          userDBObject.evaluationsPurchased_ML;
      } else {
        userObject["evaluationsPurchased_ML"] = 0;
      }
      this.setState({ userObject: userObject });
    } else {
      return;
    }
  };

  render() {
    const self = this;
    let userObject = {
      email: "placeholder",
      emailVerified: true,
      sendEmailVerification: () => "placeholder",
      authToken: "placeholder",
      customClaims: null,
      getIdToken: () => "placeholder",
      evaluationsConducted_ML: 0,
      evaluationsPurchased_ML: 0,
    };
    if (self.state.userObject) {
      //@ts-ignore
      userObject = self.state.userObject;
    }
    let showMainPage = false;
    if (self.state.userObject) {
      if (self.state.userObject.emailVerified) {
        showMainPage = true;
      }
    }
    return (
      <div className="App">
        {/*<div> The EnviMetric App will be undergoing maintenance for the next few hours, users may experience intermittent issues (Thursday, March 11, 2021)</div>*/}
        {showMainPage ? (
          <div>
            <MainPage
              userObject={userObject}
              updateUserObject={this.updateUserObject}
              userSignOut={this.userSignOut}
            />
          </div>
        ) : (
          <div>
            <Header
              userObject={this.state.userObject}
              userSignOut={this.userSignOut}
              returnToMainPage={null}
            />
            <Authentication onSuccessfulSignin={this.signInSuccessful} />
          </div>
        )}
      </div>
    );
  }
}

export default App;
