function mungeMLResultsTableData(MLResultsTableData_raw: any): any {
    /**
     * Converts data for one results table to the correct format
     * 
     * @param MLResultsTableData_raw stored in DB as string... passed here after being parsed... 
     *                                  format is {"EnviMetric Model": { "0.01": 24.0, "0.05": 29.0...}...}
     * @returns the information parsed as [{model: "EnviMetric Model", one: 24, five: 29...}, ...]
     */
    let MLResultsTableData: MLResultsTableRows = []
    Object.keys(MLResultsTableData_raw).forEach((model: string) => {
        try {
            MLResultsTableData.push({
                model: model,
                one: MLResultsTableData_raw[model]["0.01"],
                five: MLResultsTableData_raw[model]["0.05"],
                ten: MLResultsTableData_raw[model]["0.1"],
                twenty_five: MLResultsTableData_raw[model]["0.25"],
                fifty: MLResultsTableData_raw[model]["0.50"],
                seventy_five: MLResultsTableData_raw[model]["0.75"],
                ninty: MLResultsTableData_raw[model]["0.9"],
                ninty_five: MLResultsTableData_raw[model]["0.95"],
                ninty_nine: MLResultsTableData_raw[model]["0.99"]
            })
        } catch (error) {
            console.log('error: ', error);
        }
    })
    return MLResultsTableData
}

export default mungeMLResultsTableData