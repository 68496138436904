import React from "react";

// Firebase setup
const firebase = require("firebase");
const firebaseui = require("firebaseui");
const firebaseConfig = {
  apiKey: "AIzaSyBGiMPf6JReR98uAYioeOTLdoD1qRtsfCs",
  authDomain: "envimetric.firebaseapp.com",
  databaseURL: "https://envimetric.firebaseio.com",
  projectId: "envimetric",
  storageBucket: "envimetric.appspot.com",
  appId: "1:915987444545:web:77213ce63f3913b94d27c0"
};

firebase.initializeApp(firebaseConfig);
const ui = new firebaseui.auth.AuthUI(firebase.auth());

interface Props {
  onSuccessfulSignin: (userObject: UserObject) => void;
}
interface State {}

class Authentication extends React.Component<Props, State> {
  componentDidMount() {
    this.renderUserSignInUI();
  }

  renderUserSignInUI = () => {
    const self = this;
    ui.start("#firebaseui-auth-container", {
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        signInSuccessWithAuthResult: function (authResult: any, redirectUrl: any) {
          let userObject: UserObject = firebase.auth().currentUser;
          if (!userObject.emailVerified) {
            userObject
              .sendEmailVerification()
              .then(function () {
                alert("Please verify you email address and then sign in again");
                self.revertBackToSignInPage();
              })
              .catch(function (error: string) {
                console.log("error: ", error);
              });
          } else {
            self.verifiedUserSignedIn(userObject);
          }
          return false;
        },
      },
    });
  };

  revertBackToSignInPage = () => {
    this.renderUserSignInUI();
  };

  verifiedUserSignedIn = (userObject: UserObject) => {
    this.props.onSuccessfulSignin(userObject);
  };

  render() {
    return (
      <div className="Authentication">
        <div id="firebaseui-auth-container"></div>
      </div>
    );
  }
}

export default Authentication;
