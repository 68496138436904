import React, { Component } from "react";
import { InputNumber, Select, Button } from "antd";
const { Option, OptGroup } = Select;

export default class MaxGWConcentrations extends Component {
  constructor(props) {
    super(props);
    var maxGWConcentrations = [];
    if (this.props.maxGWConcentrations) {
      maxGWConcentrations = this.props.maxGWConcentrations;
    }
    this.state = { maxGWConcentrations: maxGWConcentrations, numberOfElementsChange: false };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.numberOfElementsChange) {
      return true;
    } else {
      return false;
    }
  }

  addConcentration = () => {
    let maxGWConcentrations = this.state.maxGWConcentrations;
    if (this.props.maxGWConcentrations) {
      maxGWConcentrations = this.props.maxGWConcentrations;
    }
    maxGWConcentrations.unshift({ concentration: 0, contaminantType: "unknown" });
    this.props.updateSiteSpecificData("maxGWConcentrations", maxGWConcentrations);
    this.setState({ maxGWConcentrations: maxGWConcentrations, numberOfElementsChange: true });
  };

  deleteConcentration = (concentrationIndex) => {
    let maxGWConcentrations = this.state.maxGWConcentrations;
    maxGWConcentrations.splice(concentrationIndex, 1);
    this.props.updateSiteSpecificData("maxGWConcentrations", maxGWConcentrations);
    this.setState({ maxGWConcentrations: maxGWConcentrations, numberOfElementsChange: true });
  };

  updateConcentration = (concentrationIndex, concentrationEntryData) => {
    let maxGWConcentrations = this.state.maxGWConcentrations;
    maxGWConcentrations[concentrationIndex] = concentrationEntryData;
    this.props.updateSiteSpecificData("maxGWConcentrations", maxGWConcentrations);
    this.setState({ maxGWConcentrations: maxGWConcentrations, numberOfElementsChange: false });
  };

  updateContaminantType = (concentrationIndex, concentrationEntryData) => {
    let maxGWConcentrations = this.state.maxGWConcentrations;
    maxGWConcentrations[concentrationIndex] = concentrationEntryData;
    this.props.updateSiteSpecificData("maxGWConcentrations", maxGWConcentrations);
    this.setState({ maxGWConcentrations: maxGWConcentrations, numberOfElementsChange: false });
  };

  render() {
    if (this.state.maxGWConcentrations.length === 0 && this.props.readOnly) {
      return (
        <div>
          <span>Maximum concentration data was not provided</span>
        </div>
      );
    }
    return (
      <div>
        <span> Maximum Concentrations in Source Area Groundwater </span>
        <br />
        {this.props.readOnly ? null : <Button icon="plus" onClick={this.addConcentration} />}
        {this.state.maxGWConcentrations.map((concentrationEntry, index) => (
          <ConcentrationEntry
            readOnly={this.props.readOnly}
            key={index + concentrationEntry.concentration.toString() + this.state.maxGWConcentrations.length.toString()}
            concentration={concentrationEntry.concentration}
            contaminantType={concentrationEntry.contaminantType}
            concentrationIndex={index}
            deleteConcentration={this.deleteConcentration}
            updateConcentration={this.updateConcentration}
            updateContaminantType={this.updateContaminantType}
          />
        ))}
      </div>
    );
  }
}

class ConcentrationEntry extends Component {
  constructor(props) {
    super(props);
    let precision = 2;
    if (this.PFOScompoundTypes.includes(this.props.contaminantType)) {
      precision = 4;
    }
    this.state = {
      concentration: this.props.concentration,
      contaminantType: this.props.contaminantType,
      precision: precision,
    };
  }

  PFOScompoundTypes = [
    "Perfluorobutanoic acid",
    "Perfluoropentanoic acid",
    "Perfluorohexanoic acid",
    "Perfluoroheptanoic acid",
    "Perfluorooctanoic acid ",
    "Perfluorononanoic acid",
    "Perfluorodecanoic acid ",
    "Perfluoroundecanoic acid ",
    "Perfluorododecanoic acid",
    "Perfluorotridecanoic acid ",
    "Perfluorotetradecanoic acid ",
    "Perfluorosulfonic acids",
    "Perfluorobutane sulfonic acid",
    "Perfluoropentane sulfonic acid",
    "Perfluorohexane sulfonic acid",
    "Perfluoroheptane sulfonic acid",
    "Perfluorooctane sulfonic acid",
    "Perfluorononane sulfonic acid",
    "Perfluorodecane sulfonic acid",
    "Fluorotelomer carboxylic acids",
    "5:3 Fluorotelomer carboxylic acid",
    "6:2 Fluorotelomer carboxylic acid",
    "Fluorotelomer sulfonic acids ",
    "4:2 Fluorotelomer sulfonic acid",
    "6:2 Fluorotelomer sulfonic acid",
    "8:2 Fluorotelomer sulfonic acid",
    "Perfluoroalkane sulfonamides",
    "Perfluorooctane sulfonamide",
    "N-Methyl perfluorooctane sulfonamide",
    "N-Ethyl perfluorooctane sulfonamide",
    "Perfluoroalkane sulfonamidoethanols",
    "Perfluorooctane Sulfonamidoethanol",
    "N-Methyl perfluorooctane sulfonamidoethanol",
    "N-Ethyl perfluorooctane sulfonamidoethanol",
    "Perfluoroalkane sulfonamido acetic acids",
    "Perfluorooctane sulfonamido acetic acid",
    "N-Methyl perfluorooctane sulfonamido acetic acid",
    "N-Ethyl perfluorooctane sulfonamido acetic acid",
    "4:2 Fluorotelomer alcohol",
    "6:2 Fluorotelomer alcohol",
    "8:2 Fluorotelomer alcohol",
    "10:2 Fluorotelomer alcohol",
    "Hexafluoropropylene oxide dimer acid ",
    "4,8-dioxa-3H-perfluorononanoic acid",
    "11-chloroeicosafluoro-3-oxaundecane-1-sulfonic acid",
    "9-chlorohexadecafluoro-3-oxanone-1-sulfonic acid",
  ];

  updateConcentration = (value) => {
    var concentration = value;
    var contaminantType = this.state.contaminantType || "unknown";
    const concentrationEntryData = { concentration: concentration, contaminantType: contaminantType };
    this.props.updateConcentration(this.props.concentrationIndex, concentrationEntryData);
    this.setState({ concentration: value });
  };

  updateContaminantType = (value) => {
    var contaminantType = value;
    var concentration = this.state.concentration || 0;
    const concentrationEntryData = { concentration: concentration, contaminantType: contaminantType };
    this.props.updateContaminantType(this.props.concentrationIndex, concentrationEntryData);
    let precision = 2;
    if (this.PFOScompoundTypes.includes(value)) {
      precision = 4;
    }
    this.setState({ contaminantType: value, precision: precision });
  };

  deleteConcentration = () => {
    this.props.deleteConcentration(this.props.concentrationIndex);
  };

  makeOptions = (option) => {
    return (
      <Option key={option} value={option}>
        {option}
      </Option>
    );
  };

  render() {
    return (
      <div>
        <InputNumber
          readOnly={this.props.readOnly}
          defaultValue={this.state.concentration}
          precision={this.state.precision}
          min={0}
          onChange={this.updateConcentration}
        />
        <span> (ug/L) (ppb) : </span>
        <Select
          defaultValue={this.props.contaminantType}
          style={{ width: 250 }}
          onChange={this.updateContaminantType}
          disabled={this.props.readOnly}
        >
          <OptGroup label="GasolineRelated">
            <Option value="Benzene">Benzene</Option>
            <Option value="MTBE">MTBE</Option>
            <Option value="TPH_EPH">TPH_EPH</Option>
            <Option value="Toluene">Toluene</Option>
            <Option value="Ethylbenzene">Ethylbenzene</Option>
            <Option value="Total_Xylenes">Total_Xylenes</Option>
          </OptGroup>
          <OptGroup label="Chlorinated Solvents">
            <Option value="TCE">TCE</Option>
            <Option value="PCE">PCE</Option>
            <Option value="DCE">DCE</Option>
            <Option value="VC">VC</Option>
            <Option value="TCA">TCA</Option>
            <Option value="DCA">DCA</Option>
            <Option value="Dichlorobenzene_1_4">Dichlorobenzene_1_4</Option>
          </OptGroup>
          <OptGroup label="Other">
            <Option value="Lead">Lead</Option>
            <Option value="Arsenic">Arsenic</Option>
            <Option value="Mercury">Mercury</Option>
            <Option value="PFOA">PFOA</Option>
            <Option value="PFOS">PFOS</Option>
            <Option value="Dioxane">Dioxane</Option>
          </OptGroup>
          <OptGroup label="PFAS/PFOS">{this.PFOScompoundTypes.map(this.makeOptions)}</OptGroup>
        </Select>
        {this.props.readOnly ? null : <Button icon="delete" onClick={this.deleteConcentration} />}
      </div>
    );
  }
}
