import React from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = "pk.eyJ1IjoibWFwcGlza3lsZSIsImEiOiJ5Zmp5SnV3In0.mTZSyXFbiPBbAsJCFW8kfg";

interface SiteSpecificMapProps {
    sourceLat: number;
    sourceLon: number;
    updateLat: Function;
    updateLon: Function;
    readOnly: Boolean;
}

interface SiteSpecificMapState {
    map: any;
    sourceLat: number;
    sourceLon: number;
    zoom: number;
    sourceZoneMarker: any;
}

class SiteSpecificMap extends React.Component<SiteSpecificMapProps, SiteSpecificMapState> {
    constructor(props: SiteSpecificMapProps) {
        super(props);
        let sourceLat: number, sourceLon: number
        let zoom: number = 16
        if (this.props.sourceLat && this.props.sourceLon) {
            sourceLat = this.props.sourceLat
            sourceLon = this.props.sourceLon
        } else {
            sourceLat = 39.5
            sourceLon = -101
            zoom = 4
        }

        this.state = {
            map: null,
            sourceLat: sourceLat,
            sourceLon: sourceLon,
            zoom: zoom,
            sourceZoneMarker: null
        };
    }

    componentDidMount() {
        const self = this
        const map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/satellite-streets-v11",
            center: [self.state.sourceLon, self.state.sourceLat],
            zoom: self.state.zoom,
            attributionControl: false,
        });

        map.on("load", function () {
            map.resize();
        });
        self.plotSourceZoneLocation(map)

        if (!this.props.readOnly) {
            map.on("click", function (e) {
                const targetClass = (e.originalEvent.target as Element).className;
                if (targetClass === "mapboxgl-canvas") {
                    const location = e.lngLat
                    self.props.updateLat(location.lat)
                    self.props.updateLon(location.lng)
                }
            })
        }

        self.setState({ map: map })
    }

    componentDidUpdate(prevProps: SiteSpecificMapProps) {
        if (prevProps.sourceLat === this.props.sourceLat && prevProps.sourceLon === this.props.sourceLon) {
            return false
        } else {
            this.plotSourceZoneLocation(this.state.map)
        }
    }

    plotSourceZoneLocation(map: any) {
        const self = this
        if (self.state.sourceZoneMarker) {
            self.state.sourceZoneMarker.remove()
        }
        if (self.props.sourceLon && self.props.sourceLat) {
            if (typeof self.props.sourceLon == "number" && typeof self.props.sourceLat == "number") {
                let marker: any = new mapboxgl.Marker({ draggable: !self.props.readOnly }).setLngLat([self.props.sourceLon, self.props.sourceLat]).addTo(map)
                // @ts-ignore
                function onDragEnd() {
                    let lngLat = marker.getLngLat();
                    self.props.updateLat(lngLat.lat)
                    self.props.updateLon(lngLat.lng)
                }
                marker.on("dragend", onDragEnd)
                self.setState({ sourceZoneMarker: marker })
            }
        }

    }

    render() {
        return (
            <div className="Map MajorComponent">
                {this.props.readOnly ? (
                    <h6>Map of Source Area Location</h6>
                ) : (
                        <div>
                            <h6>Map of Source Area Location</h6><div>(click on location to drop source area pin)</div>
                        </div>
                    )}
                <div className="MapOutsideContainer">
                    <div id="mapContainer" className="MapContainer" />
                </div>
            </div>
        );
    }
}

export default SiteSpecificMap;