function mungeSiteData_dictForTable(siteData_dict: SiteData_dict): SiteDataForTable {
    let siteDataForTable: SiteDataForTable = []
    Object.keys(siteData_dict).forEach((siteName: string) => {
        try {
            siteDataForTable.push({
                siteName: siteName,
                created: siteData_dict[siteName].created,
                status: siteData_dict[siteName].status,
                siteData: statusToSiteDataWord(siteData_dict[siteName].status),
                enviMetric_ML: statusToEnviMetric_MLWord(siteData_dict[siteName].status)
            })
        } catch (error) {
            console.log('error: ', error);
        }
    })
    return siteDataForTable
}

function statusToSiteDataWord(status: SiteStatus): SiteDataStatus {
    switch (status) {
        case "new":
            return "add"
        case "in progress (more data needed)":
            return "Edit Site Data"
        case "in progress (ready to run)":
            return "Edit Site Data"
        case "completed":
            return "View Site Data"
        case "in progress (running)":
            return "View Site Data"

        default:
            return "Edit Site Data"
    }
}

function statusToEnviMetric_MLWord(status: SiteStatus): EnviMetricMLStatus {
    switch (status) {
        case "new":
            return "need site data"
        case "in progress (more data needed)":
            return "Run EnviMetric Model" //"need additional site data"
        case "in progress":
            return "Run EnviMetric Model" //"need additional site data"
        case "in progress (ready to run)":
            return "Run EnviMetric Model"
        case "completed":
            return "Download Results"
        case "in progress (running)":
            return "Download Results"

        default:
            return "need site data"
    }
}

export default mungeSiteData_dictForTable