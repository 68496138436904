import React from "react";
import ReactTable6 from "react-table-6";
import mungeMLResultsTableData from "./../../helpers/mungeMLResultsTableData"

interface MLResultsTableProps {
    MLResultsTableData_raw: any;
    dimension: Dimension
}

interface MLResultsTableState {
    MLResultsTableData: any
}

class MLResultsTable extends React.Component<MLResultsTableProps, MLResultsTableState> {
    constructor(props: MLResultsTableProps) {
        super(props);
        this.state = { MLResultsTableData: mungeMLResultsTableData(props.MLResultsTableData_raw) }
    }


    columns = [
        {
            Header: "",
            accessor: "model",
            width: 350
            /*,
            getProps: (state: any, rowInfo: any, column: any) => {
                return {
                    style: {
                        background: rowInfo.model === "EnviMetric Model" ? 'red' : 'blue',
                    },
                };
            }
            */
        },
        {
            Header: "1%",
            accessor: "one"
        },
        {
            Header: "5%",
            accessor: "five",
        },
        {
            Header: "10%",
            accessor: "ten",
        },
        {
            Header: "25%",
            accessor: "twenty_five"
        },
        {
            Header: "50%",
            accessor: "fifty",
        },
        {
            Header: "75%",
            accessor: "seventy_five",
        },
        {
            Header: "90%",
            accessor: "ninty"
        },
        {
            Header: "95%",
            accessor: "ninty_five",
        },
        {
            Header: "99%",
            accessor: "ninty_nine",
        }
    ];

    render() {
        return (
            <div>
                <ReactTable6
                    className="-striped SiteTable SiteTableTable MajorComponent"
                    columns={this.columns}
                    data={this.state.MLResultsTableData}
                    showPagination={false}
                    defaultPageSize={this.state.MLResultsTableData.length}
                    getTrProps={(state: any, rowInfo: any, column: any) => {
                        return {
                            style: {
                                fontWeight: rowInfo.row.model === "EnviMetric Model" ? "bold" : null
                            }
                        }
                    }}
                />
                <div>This table shows the EnviMetric model’s confidence that the plume {this.props.dimension.toLowerCase()} is less than the distance shown (in feet) along with the percentages of different data subsets with plume {this.props.dimension.toLowerCase()} less than the distance shown </div>
            </div>
        );
    }
}

export default MLResultsTable;
