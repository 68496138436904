import Axios from "axios";
import React from "react";
import SiteDataEntry from "./SiteDataEntry"
import SiteTable from "./SiteTable";
import MLResults from "./MLResults"
import Header from "./Header"
import { Input } from "antd";
import "./../App.css"
const firebase = require("firebase")


interface MainPageProps {
  userObject: UserObject;
  updateUserObject: Function;
  userSignOut: Function;
}

interface MainPageState {
  view: workFlowView
  siteData_dict: any
  newSiteName: string
  siteNameInView: string | null
  loading: Boolean
  updateInterval: any;
  existingSiteNames: string[];
}

class MainPage extends React.Component<MainPageProps, MainPageState> {
  constructor(props: MainPageProps) {
    super(props);
    this.state = {
      view: "siteTable",
      siteData_dict: null,
      newSiteName: "",
      siteNameInView: null,
      loading: true,
      updateInterval: null,
      existingSiteNames: []
    }
  }

  async componentDidMount(): Promise<void> {
    this.getDataFromBE()
    this.setUpdateInterval()
    this.getExistingSiteNames()
  }

  componentWillUnmount() {
    const self = this
    const updateInterval = self.state.updateInterval
    clearInterval(updateInterval)
  }

  async getDataFromBE() {
    if (this.props.userObject) {
      const userToken: any = await firebase.auth().currentUser.getIdToken();
      const siteData_raw = await Axios.get("https://us-central1-envimetric.cloudfunctions.net/getSiteData_0_1_0?userToken=" + userToken)
      const siteData_dict = siteData_raw.data.siteData
      this.setState({ siteData_dict: siteData_dict })
    }
  }

  setUpdateInterval = () => {
    const self = this
    const updateInterval: any = setInterval(async () => {
      self.getDataFromBE()
    }, 5000);
    self.setState({ updateInterval: updateInterval })
  }

  async getExistingSiteNames() {
    const existingSiteNames_raw = await Axios.get("https://us-central1-envimetric.cloudfunctions.net/getListOfCurrentSites_0_1_0")
    const existingSiteNames = existingSiteNames_raw.data
    this.setState({ existingSiteNames: existingSiteNames })
  }

  returnToMainPage = () => {
    const self = this
    const newView = "siteTable"
    setTimeout(() => {
      self.getDataFromBE()
      self.setState({ view: newView, newSiteName: "" })
    }, 1000)
  }

  toggleView = (siteNameInView: string | null, readOnly: Boolean | null, resultsML: Boolean = false) => {
    const self = this
    const currentView: workFlowView = self.state.view
    let newView: workFlowView
    if (currentView === "siteTable") {
      if (resultsML) {
        newView = "MLResultsView"
      } else if (readOnly) {
        newView = "siteDataEntryReadOnly"
      } else {
        newView = "siteDataEntry"
      }
      self.setState({ view: newView, siteNameInView: siteNameInView })
    } else {
      newView = "siteTable"
      setTimeout(() => {
        self.getDataFromBE()
        self.setState({ view: newView, siteNameInView: siteNameInView, newSiteName: "" })
      }, 1000)
    }
  }

  isOnlyNumerals = (value: string) => {
    return /^\d+$/.test(value)
  }

  addNewSite = () => {
    // Check that site name is valid
    if (this.state.newSiteName === "") {
      alert("Please enter a unique site name.")
    } else if (this.state.existingSiteNames.includes(this.state.newSiteName)) {
      alert("A site with that name already exists (either from you or from another user). \nPlease use a unique site name.")
    } else if (this.isOnlyNumerals(this.state.newSiteName)) {
      alert("Site names containing only numerals are not currently supported. \nPlease include letters in your site name. " + this.state.newSiteName + "_site for example would work.")
    } else {
      this.toggleView(this.state.newSiteName, false)
    }
  }

  updateNewSiteName = (event: any) => {
    const siteNameNoSpaces = event.target.value.replace(" ", "_")
    this.setState({ newSiteName: siteNameNoSpaces })
  }

  toggleLoading = () => {
    this.setState({ loading: false })
  }

  render() {
    let siteDataEntryReadOnly = false
    if (this.state.view === "siteDataEntryReadOnly") siteDataEntryReadOnly = true
    return (
      <div className="MainPage">
        <Header
          userObject={this.props.userObject}
          userSignOut={this.props.userSignOut}
          returnToMainPage={this.returnToMainPage} />
        {this.state.view === "siteTable" ? (
          <div>
            <SiteTable
              userObject={this.props.userObject}
              toggleView={this.toggleView}
              siteData_dict={this.state.siteData_dict}
              toggleLoading={this.toggleLoading}
              updateUserObject={this.props.updateUserObject}
            />
            {this.state.loading ? (null) : (
              <div className="addNewSiteButtonDiv">
                <Input
                  className="newSiteNameInput"
                  placeholder="Unique Site Name"
                  value={this.state.newSiteName}
                  onChange={this.updateNewSiteName}
                />
                <button className="btn btn-primary addNewSiteButton" onClick={() => this.addNewSite()}>Add New Site</button>
              </div>
            )}
          </div>
        ) : (
            <div>
              {this.state.view === "MLResultsView" ? (

                <MLResults
                  userObject={this.props.userObject}
                  siteData_dict={this.state.siteData_dict}
                  siteNameInView={this.state.siteNameInView}
                />
              ) : (
                  < SiteDataEntry
                    userObject={this.props.userObject}
                    toggleView={this.toggleView}
                    siteNameInView={this.state.siteNameInView}
                    siteData_dict={this.state.siteData_dict}
                    readOnly={siteDataEntryReadOnly}
                  />)}
            </div>
          )}

      </div>
    );
  }
}

export default MainPage;
