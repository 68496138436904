import React, { Component } from "react";
import { InputNumber, Select, Button, Popover } from "antd";
const { Option, OptGroup } = Select;

export default class SoilProfile extends Component {
  constructor(props) {
    super(props);
    let soilProfile = [];
    if (this.props.soilProfile) {
      soilProfile = this.props.soilProfile;
    }
    this.state = { soilProfile: soilProfile, numberOfElementsChanged: false };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.numberOfElementsChanged) {
      return true;
    } else {
      return false;
    }
  }

  addSoilLayerAbove = () => {
    let soilProfile = this.state.soilProfile;
    soilProfile.unshift({ height: [0, 0], type: "unknown" });
    this.props.updateSiteSpecificData("soilProfile", soilProfile);
    this.setState({ soilProfile: soilProfile, numberOfElementsChanged: true });
  };

  addSoilLayerBelow = (soilLayerIndex) => {
    let soilProfile = this.state.soilProfile;
    soilProfile.splice(soilLayerIndex + 1, 0, { height: [0, 0], type: "unknown" });
    this.props.updateSiteSpecificData("soilProfile", soilProfile);
    this.setState({ soilProfile: soilProfile, numberOfElementsChanged: true });
  };

  updateSoilLayer = (soilLayerIndex, soilLayerData) => {
    let soilProfile = this.state.soilProfile;
    soilProfile[soilLayerIndex] = soilLayerData;
    this.props.updateSiteSpecificData("soilProfile", soilProfile);
    this.setState({ soilProfile: soilProfile, numberOfElementsChanged: false });
  };

  deleteSoilLayer = (soilLayerIndex) => {
    let soilProfile = this.state.soilProfile;
    soilProfile.splice(soilLayerIndex, 1);
    this.props.updateSiteSpecificData("soilProfile", soilProfile);
    this.setState({ soilProfile: soilProfile, numberOfElementsChanged: true });
  };

  render() {
    if (this.state.soilProfile.length === 0 && this.props.readOnly) {
      return (
        <div>
          <span> Soil Profile was not provided </span>
        </div>
      );
    }
    return (
      <div>
        <span> Depth in feet (below ground surface is negative) </span>
        <br />
        {this.props.readOnly ? null : <Button icon="plus" onClick={this.addSoilLayerAbove} />}
        {this.state.soilProfile.map((soilLayer, index) => (
          <LithologyEntry
            key={index + soilLayer.height[0].toString() + soilLayer.height[1].toString()}
            topDepth={soilLayer.height[0]}
            bottomDepth={soilLayer.height[1]}
            soilType={soilLayer.type}
            soilLayerIndex={index}
            updateSoilLayer={this.updateSoilLayer}
            deleteSoilLayer={this.deleteSoilLayer}
            addSoilLayerBelow={this.addSoilLayerBelow}
            readOnly={this.props.readOnly}
          />
        ))}
      </div>
    );
  }
}

class LithologyEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topDepth: this.props.topDepth * 3.28084,
      bottomDepth: this.props.bottomDepth * 3.28084,
      soilType: this.props.soilType,
    };
  }

  updateSoilLayerTop = (value) => {
    var bottomDepth = this.state.bottomDepth || 0;
    var soilType = this.state.soilType || "unknown";
    const soilLayerData = { height: [value, bottomDepth].map((x) => x / 3.28084), type: soilType };
    this.props.updateSoilLayer(this.props.soilLayerIndex, soilLayerData);
    this.setState({ topDepth: value });
  };

  updateSoilLayerBottom = (value) => {
    var topDepth = this.state.topDepth || 0;
    var soilType = this.state.soilType || "unknown";
    const soilLayerData = { height: [topDepth, value].map((x) => x / 3.28084), type: soilType };
    this.props.updateSoilLayer(this.props.soilLayerIndex, soilLayerData);
    this.setState({ bottomDepth: value });
  };

  updateSoilLayerType = (value) => {
    var bottomDepth = this.state.bottomDepth || 0;
    var topDepth = this.state.topDepth || 0;
    const soilLayerData = { height: [topDepth, bottomDepth].map((x) => x / 3.28084), type: value };
    this.props.updateSoilLayer(this.props.soilLayerIndex, soilLayerData);
    this.setState({ soilType: value });
  };

  deleteSoilLayer = () => {
    this.props.deleteSoilLayer(this.props.soilLayerIndex);
  };

  addSoilLayerBelow = () => {
    this.props.addSoilLayerBelow(this.props.soilLayerIndex);
  };

  render() {
    return (
      <div>
        <InputNumber
          defaultValue={this.state.topDepth}
          precision={2}
          onChange={this.updateSoilLayerTop}
          readOnly={this.props.readOnly}
        />
        <span> - </span>
        <InputNumber
          defaultValue={this.state.bottomDepth}
          precision={2}
          onChange={this.updateSoilLayerBottom}
          readOnly={this.props.readOnly}
        />
        <span> : </span>
        <Select
          defaultValue={this.props.soilType}
          style={{ width: 180 }}
          onChange={this.updateSoilLayerType}
          disabled={this.props.readOnly}
        >
          <OptGroup label="General">
            <Option value="unknown">unknown</Option>
            <Option value="sand">sand</Option>
            <Option value="silt">silt</Option>
            <Option value="clay">clay</Option>
            <Option value="bedRock">bed rock</Option>
          </OptGroup>
          <OptGroup label="More Specific">
            <Option value="gravel">gravel</Option>
            <Option value="c_sand">c. sand</Option>
            <Option value="m_sand">m. sand</Option>
            <Option value="f_sand">f. sand</Option>
            <Option value="siltySandy">silty sand</Option>
            <Option value="sandySilt">sandy silt</Option>
            <Option value="silt">silt</Option>
            <Option value="clayeySilt">clayey silt</Option>
            <Option value="siltyClay">silty clay</Option>
            <Option value="clay">clay</Option>
          </OptGroup>
          <OptGroup label="Special">
            <Option value="topSoil">top soil</Option>
            <Option value="fill">fill</Option>
            <Option value="glacialTill">glacial till</Option>
            <Option value="organics">organics</Option>
            <Option value="weatheredBedrock">weathered bedrock</Option>
            <Option value="competentBedrock">competent bedrock</Option>
            <Option value="limestone">limestone</Option>
            <Option value="notSampled">not sampled / no-recovery</Option>
          </OptGroup>
          <OptGroup label="USCS Letters">
            <Option value="CH">CH</Option>
            <Option value="CL">CL</Option>
            <Option value="GC">GC</Option>
            <Option value="GM">GM</Option>
            <Option value="GP">GP</Option>
            <Option value="GW">GW</Option>
            <Option value="ML">ML</Option>
            <Option value="MH">MH</Option>
            <Option value="OH">OH</Option>
            <Option value="OL">OL</Option>
            <Option value="Pt">Pt</Option>
            <Option value="SC">SC</Option>
            <Option value="SM">SM</Option>
            <Option value="SP">SP</Option>
            <Option value="SW">SW</Option>
          </OptGroup>
        </Select>
        {this.props.readOnly ? null : <Button icon="delete" onClick={this.deleteSoilLayer} />}
        {this.props.readOnly ? null : (
          <Popover content="Add Soil Layer Below">
            <Button icon="plus" onClick={this.addSoilLayerBelow} />
          </Popover>
        )}
      </div>
    );
  }
}
