import Axios from "axios";
import React from "react";
import ReactTable6 from "react-table-6";
import mungeSiteData_dictForTable from "./../helpers/mungeSiteData_dict"
import formatSiteDataForML from "./../helpers/formatSiteDataForML"
// @ts-ignore
import Loader from "react-loader-spinner"
const FileDownload = require('js-file-download');
const firebase = require("firebase")

Axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8"
Axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*"

interface SiteTableProps {
  userObject: UserObject
  toggleView: Function
  siteData_dict: SiteData_dict | null
  toggleLoading: Function
  updateUserObject: Function
}

interface SiteTableState {
  data: any
  loading: Boolean
  siteToRun: string
}

class SiteTable extends React.Component<SiteTableProps, SiteTableState> {
  constructor(props: SiteTableProps) {
    super(props);
    this.state = { data: [], loading: true, siteToRun: "" }
  }

  componentDidMount() {
    this.updateTableData()
  }

  componentDidUpdate(prevProps: SiteTableProps) {
    if (this.props.siteData_dict !== prevProps.siteData_dict) {
      this.updateTableData()
    }
  }

  updateTableData() {
    if (this.props.siteData_dict) {
      const data = mungeSiteData_dictForTable(this.props.siteData_dict)
      this.props.toggleLoading()
      this.setState({ data: data, loading: false })
    }
  }

  async downloadMLResults(siteName: string) {
    const userToken: string = await firebase.auth().currentUser.getIdToken();
    Axios.get("https://mlapi.envimetric.com/downloadMLResults?userToken=" + userToken + "&siteName=" + siteName, {
      responseType: 'blob',
    })
      .then((response) => {
        FileDownload(response.data, "MLResults_" + siteName + ".zip");
      });
  }

  runSite = async (siteName: string) => {
    const self = this
    const userToken: string = await firebase.auth().currentUser.getIdToken();
    self.changeSiteToRunning(siteName, userToken)
    // @ts-ignore
    const siteSpecificData = formatSiteDataForML(self.props.siteData_dict[siteName])
    Axios.post("https://mlapi.envimetric.com/runMLPipelineForSite?userToken=" + userToken + "&siteName=" + siteName, siteSpecificData)
      .then((response) => {
        //@ts-ignore
        self.props.updateUserObject("evaluationsConducted_ML", self.props.userObject.evaluationsConducted_ML + 1)
      })
      .catch((error) => {
        console.log('error: ', error);
      })
  }

  changeSiteToRunning = (siteName: string, userToken: string) => {
    const self = this
    const data = self.state.data
    Axios.get("https://us-central1-envimetric.cloudfunctions.net/changeSiteStatus_0_1_0?userToken=" + userToken + "&siteName=" + siteName + "&status=in progress (running)")
    const updatedData = data.filter((siteEntry: SiteDataTableEntry) => {
      if (siteEntry.siteName === siteName) {
        siteEntry.status = "in progress (running)"
      }
      return siteEntry
    })
    self.setState({ data: updatedData })
  }

  setSiteToState = (siteName: string) => {
    const self = this
    self.setState({ siteToRun: siteName })
  }

  removeSite(siteName: string) {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ loading: true })
        const userToken: string = await firebase.auth().currentUser.getIdToken();
        await Axios.get("https://us-central1-envimetric.cloudfunctions.net/removeSiteFromUserList_0_1_0?userToken=" + userToken + "&siteName=" + siteName)
        let siteTableData = this.state.data
        let filteredSiteTableData = siteTableData.filter((row: any) => { // eslint-disable-line
          if (row.siteName !== siteName) return row
        })
        this.setState({ data: filteredSiteTableData, loading: false })
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  columns = [
    {
      Header: "Site Name",
      accessor: "siteName",
    },
    {
      Header: "Date Created",
      accessor: "created"
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Site Data",
      accessor: "siteData",
      Cell: (row: any) => (
        <SiteDataButton row={row} toggleView={this.props.toggleView} />
      )
    },
    {
      Header: "Actions",
      accessor: "enviMetric_ML",
      Cell: (row: any) => (
        <div>
          <EnviMetricMLButton
            row={row}
            downloadMLResults={this.downloadMLResults}
            runSite={this.runSite}
            data={this.state.data}
            setSiteToState={this.setSiteToState}
            viewResults={this.props.toggleView} />
        </div>)
    },
    {
      Header: "",
      accessor: "remove",
      Cell: (row: any) => (
        <button
          className={"btn btn-light btn-sm removeSiteButton"}
          type="button"
          onClick={() => { this.removeSite(row.original.siteName) }}
        >
          <i className="fas fa-trash" />
        </button>
      ),
      width: 60
    }
  ];

  render() {
    //@ts-ignore
    let evaluationsRemaining = this.props.userObject.evaluationsPurchased_ML - this.props.userObject.evaluationsConducted_ML
    let modalTabIndex = { tabIndex: 1 }
    if (this.state.loading || this.state.data.length === 0) {
      return (
        <div>
          <Loader
            type="Audio" //"ThreeDots" wasn't
            color="rgb(0, 217, 255)"
            height={100}
            width={100}
          />
        </div>
      );
    } else {
      return (
        <div>
          <ReactTable6
            className="-striped SiteTable SiteTableTable MajorComponent"
            columns={this.columns}
            data={this.state.data}
            showPagination={true}
            defaultPageSize={5}
          />

          <div id="runSiteModal" className="modal" role="dialog" {...modalTabIndex}>
            <div className="modal-dialog" role="document">
              {evaluationsRemaining > 0 ? (
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Run EnviMetric Machine Learning Evaluation for {this.state.siteToRun}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>You have {evaluationsRemaining} evaluations remaining. After running this site you will have {evaluationsRemaining - 1} remaining.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.runSite(this.state.siteToRun)}>Run</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              ) : (
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">You currently have no evaluations available for use.</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>Please contact Azimuth1 to discuss purchasing more evaluation credits.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Back</button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SiteTable;


// Helper components
function SiteDataButton(props: any) {
  let readOnly = false
  if (props.row.original.siteData === "View Site Data") readOnly = true
  return (
    <button
      className={"btn btn-light btn-sm SiteDataButton"}
      type="button"
      onClick={() => props.toggleView(props.row.original.siteName, readOnly)}
    >
      {props.row.original.siteData}
    </button>
  )
}

function EnviMetricMLButton(props: any) {
  function buttonAction(siteName: string) {
    switch (props.row.original.enviMetric_ML) {
      case "Download Results":
        props.downloadMLResults(siteName)
        break
      case "Run EnviMetric Model":
        props.setSiteToState(siteName)
        break
      default:
        console.log("need more data... ")
    }
  }

  let running = false
  let completed = false
  props.data.forEach((siteEntry: SiteDataTableEntry) => {
    if (siteEntry.siteName === props.row.original.siteName) {
      if (siteEntry.status === "in progress (running)") {
        running = true
      }
      else if (siteEntry.status === "completed") {
        completed = true
      }
    }
  })

  return (
    <div>
      {running ? (<div>
        running
        <Loader
          className="runningLoadSpinner"
          type="Audio"
          color="rgb(0, 217, 255)"
          height={20}
          width={20}
        />
      </div>) : (
          <div>
            <button
              className={"btn btn-light btn-sm enviMetric_ML_Button"}
              type="button"
              data-toggle={props.row.original.enviMetric_ML === "Run EnviMetric Model" ? ("modal") : ("")}
              data-target={props.row.original.enviMetric_ML === "Run EnviMetric Model" ? ("#runSiteModal") : ("")}
              onClick={() => { buttonAction(props.row.original.siteName) }}
            >
              {props.row.original.enviMetric_ML}
            </button>
            {completed ? (
              <button
                className={"btn btn-light btn-sm enviMetric_ML_Button view_Results_Button"}
                type="button"
                onClick={() => { props.viewResults(props.row.original.siteName, false, true) }}
              >
                View Results
              </button>) : (null)}
          </div>
        )}

    </div>
  )
}
