import React, { Component } from "react";
import NumericInput from "./NumericInput";

export default class GeoChemistry extends Component {
  constructor(props) {
    super(props);
    let geoChemistryData = {};
    if (this.props.geoChemistryData) {
      geoChemistryData = this.props.geoChemistryData;
    }
    this.state = { geoChemistryData: geoChemistryData };
  }

  updateGeoChemistryData = (key, value) => {
    let geoChemistryData = this.state.geoChemistryData;
    geoChemistryData[key] = value;
    this.props.updateSiteSpecificData("geoChemistryData", geoChemistryData);
    this.setState({ geoChemistryData: geoChemistryData });
  };

  render() {
    return (
      <div>
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="pH"
          featureDescription="pH"
          featureString="pH"
          featureUpdateFunction={this.updateGeoChemistryData}
          featurePrecision={2}
          featureMin={0}
          featureMax={14}
          data={this.state.geoChemistryData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="ORP (Oxidation Reduction Potential)"
          featureDescription="ORP (mV)"
          featureString="ORP"
          featureUpdateFunction={this.updateGeoChemistryData}
          featurePrecision={0}
          featureMin={-1000}
          featureMax={1000}
          data={this.state.geoChemistryData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="DO (Dissolved Oxygen)"
          featureDescription="DO (mg/L)"
          featureString="DO"
          featureUpdateFunction={this.updateGeoChemistryData}
          featurePrecision={2}
          featureMin={0}
          featureMax={15}
          data={this.state.geoChemistryData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="TOC (Total Organic Carbon of soil)"
          featureDescription="TOC (mg/kg)"
          featureString="TOC"
          featureUpdateFunction={this.updateGeoChemistryData}
          featurePrecision={1}
          featureMin={0}
          featureMax={1000000}
          data={this.state.geoChemistryData}
        />
      </div>
    );
  }
}
