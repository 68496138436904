import React from "react";
import { Collapse } from "antd";
import MLResultsMap from "./MLResultsSubComponents/MLResultsMap";
import MLResultsTable from "./MLResultsSubComponents/MLResultsTable"
import Axios from "axios";
// @ts-ignore
import Loader from "react-loader-spinner"
const firebase = require("firebase")

const { Panel } = Collapse;

interface MLResultsProps {
    userObject: UserObject | null;
    siteData_dict: SiteData_dict;
    siteNameInView: string | null;
}

interface MLResultsState {
    userToken: string | null;
    generalSiteInfo: GeneralSiteInfo;
    siteSpecificData: SiteSpecificData | any;
    sourceLat: number;
    sourceLon: number;
    results_GeoJSON: any;
    resultsTableLength: MLResultsTableRows | null;
    resultsTableWidth: MLResultsTableRows | null;
    resultsTableDepth: MLResultsTableRows | null;
    resultsUnavailable: boolean;
    loading: boolean;
}

class MLResults extends React.Component<MLResultsProps, MLResultsState> {
    constructor(props: MLResultsProps) {
        super(props);
        const self = this
        if (this.props.siteNameInView) {
            const siteData: any = self.props.siteData_dict[this.props.siteNameInView]
            const siteSpecificData: any = siteData.siteSpecificData
            self.state = {
                generalSiteInfo: siteSpecificData.generalInfo,
                siteSpecificData: siteSpecificData,
                sourceLat: siteSpecificData.generalInfo.lat,
                sourceLon: siteSpecificData.generalInfo.lon,
                results_GeoJSON: null,
                resultsTableLength: null,
                resultsTableWidth: null,
                resultsTableDepth: null,
                userToken: null,
                resultsUnavailable: false,
                loading: true
            }
        }
    }

    componentDidMount() {
        this.addUserTokenAndMLResultsToState()
    }

    async addUserTokenAndMLResultsToState() {
        const self = this
        const userToken: string = await firebase.auth().currentUser.getIdToken();
        const MLResultsData_raw: any = await Axios.get("https://us-central1-envimetric.cloudfunctions.net/getMLResultsData_0_1_0?userToken=" + userToken + "&siteName=" + this.props.siteNameInView)
        const MLResultsData: MLResultsData | any = MLResultsData_raw.data
        if (MLResultsData === "no site results available") {
            self.setState({ loading: false, resultsUnavailable: true })
        } else {
            self.setState({
                loading: false,
                userToken: userToken,
                results_GeoJSON: MLResultsData.results_geoJSON,
                resultsTableLength: MLResultsData.results_table_Length,
                resultsTableWidth: MLResultsData.results_table_Width,
                resultsTableDepth: MLResultsData.results_table_Depth,
            })
        }
    }

    render() {
        let siteName: string | null = this.state.generalSiteInfo.siteName
        if (!siteName) {
            siteName = this.state.siteSpecificData.siteName
        }
        if (this.state.loading) {
            return (
                <div>
                    <Loader
                        type="Audio"
                        color="rgb(0, 217, 255)"
                        height={100}
                        width={100}
                    />
                </div>
            )
        } else {

            if (this.state.resultsUnavailable) {
                return (
                    <div>
                        <h4>Viewing results for this site in the browser is not currently available.</h4>
                        <h6>The site may have been analyzed prior to this feature being released. Please download the results intead.</h6>
                    </div>
                )
            } else {
                return (
                    <div className="MLResults">
                        <h4>{this.props.siteNameInView} - Machine Learning Model Results</h4>
                        <Collapse defaultActiveKey={["MLResultsMap", "length", "width", "depth"]}>
                            <Panel header="Plume Predictions Map" key="MLResultsMap">
                                <MLResultsMap
                                    sourceLat={this.state.sourceLat}
                                    sourceLon={this.state.sourceLon}
                                    results_GeoJSON={this.state.results_GeoJSON}
                                />
                            </Panel>
                            <Panel header="Plume Length Predictions" key="length">
                                <MLResultsTable
                                    MLResultsTableData_raw={JSON.parse(this.state.resultsTableLength)}
                                    dimension="Length"
                                />
                                <br />
                                <br />
                                <img className="MLResultsGraph" src={"https://mlapi.envimetric.com/MLResultsImage?userToken=" + this.state.userToken + "&siteName=" + this.props.siteNameInView + "&plumeProperty=Length"} alt="plume length kernal density estimates" />
                                <div>This chart shows the distribution of the kernel density estimates and represents the information provided in above table graphically. The Y-axis is the percentage of predictions (i.e. percentage of plumes within the EnviMetric database, or within the referenced subset of the EnviMetric database) at that particular plume length</div>
                            </Panel>
                            <Panel header="Plume Width Predictions" key="width">
                                <MLResultsTable
                                    MLResultsTableData_raw={JSON.parse(this.state.resultsTableWidth)}
                                    dimension="Width"
                                />
                                <br />
                                <br />
                                <img className="MLResultsGraph" src={"https://mlapi.envimetric.com/MLResultsImage?userToken=" + this.state.userToken + "&siteName=" + this.props.siteNameInView + "&plumeProperty=Width"} alt="plume width kernal density estimates" />
                                <div>This chart shows the distribution of the kernel density estimates and represents the information provided in above table graphically. The Y-axis is the percentage of predictions (i.e. percentage of plumes within the EnviMetric database, or within the referenced subset of the EnviMetric database) at that particular plume width</div>
                            </Panel>
                            <Panel header="Plume Depth Predictions" key="depth">
                                <MLResultsTable
                                    MLResultsTableData_raw={JSON.parse(this.state.resultsTableDepth)}
                                    dimension="Depth"
                                />
                                <br />
                                <br />
                                <img className="MLResultsGraph" src={"https://mlapi.envimetric.com/MLResultsImage?userToken=" + this.state.userToken + "&siteName=" + this.props.siteNameInView + "&plumeProperty=Depth"} alt="plume depth kernal density estimates" />
                                <div>This chart shows the distribution of the kernel density estimates and represents the information provided in above table graphically. The X-axis is the percentage of predictions (i.e. percentage of plumes within the EnviMetric database, or within the referenced subset of the EnviMetric database) at that particular plume depth</div>
                            </Panel>
                        </Collapse>
                    </div>
                )
            }
        }
    }
}

export default MLResults;