import React from "react";
import mapboxgl from "mapbox-gl";
import { Checkbox } from "antd"

mapboxgl.accessToken = "pk.eyJ1IjoibWFwcGlza3lsZSIsImEiOiJ5Zmp5SnV3In0.mTZSyXFbiPBbAsJCFW8kfg";

interface MLResultsMapProps {
    sourceLat: number;
    sourceLon: number;
    results_GeoJSON: any;
}

interface MLResultsMapState {
    map: any;
    sourceLat: number;
    sourceLon: number;
    zoom: number;
    sourceZoneMarker: any;
    confidenceIntervalsInView: Array<string>;
}

class MLResultsMap extends React.Component<MLResultsMapProps, MLResultsMapState> {
    constructor(props: MLResultsMapProps) {
        super(props);
        let sourceLat: number = this.props.sourceLat
        let sourceLon: number = this.props.sourceLon
        let zoom: number = 15

        this.state = {
            map: null,
            sourceLat: sourceLat,
            sourceLon: sourceLon,
            zoom: zoom,
            sourceZoneMarker: null,
            confidenceIntervalsInView: ["50%", "90%"]
        };
    }

    componentDidMount() {
        const self = this
        const map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/satellite-streets-v11",
            center: [self.state.sourceLon, self.state.sourceLat],
            zoom: self.state.zoom,
            attributionControl: false,
        });

        map.on("load", function () {
            map.resize();
            self.plotSourceZoneLocation(map)
            self.plotResults(map)
        });

        self.setState({ map: map })
    }

    componentDidUpdate(prevProps: MLResultsMapProps, prevState: MLResultsMapState) {
        if (prevState.confidenceIntervalsInView === this.state.confidenceIntervalsInView) {
            return false
        } else {
            this.plotResults(this.state.map)
        }
    }

    plotSourceZoneLocation(map: any) {
        const self = this
        if (self.props.sourceLon && self.props.sourceLat) {
            let marker: any = new mapboxgl.Marker({ draggable: false }).setLngLat([self.props.sourceLon, self.props.sourceLat]).addTo(map)
            self.setState({ sourceZoneMarker: marker })
        }
    }

    plotResults(map: any) {
        const self = this
        const percentageMapping = {
            "0.01": "1%",
            "0.05": "5%",
            "0.1": "10%",
            "0.25": "25%",
            "0.5": "50%",
            "0.75": "75%",
            "0.9": "90%",
            "0.95": "95%",
            "0.99": "99%"
        }
        self.props.results_GeoJSON.features.forEach((feature: any) => {
            const percentage: string = feature["properties"]["percentage"]
            try {
                if (Object.keys(map.style.sourceCaches).includes('plume' + percentage)) {
                    map.removeLayer('plume' + percentage + 'line')
                    map.removeSource('plume' + percentage)
                }
            } catch {
            }
            // @ts-ignore
            if (self.state.confidenceIntervalsInView.includes(percentageMapping[percentage])) {
                map.addSource('plume' + percentage, {
                    'type': 'geojson',
                    'data': feature
                })
                let lineDashArray = [5, 3]
                let lineWidth = 3
                let lineColor = "white"
                if (percentage === "0.5") {
                    lineDashArray = [1, 0]
                    lineWidth = 4
                    lineColor = "white"
                }
                map.addLayer({
                    'id': 'plume' + percentage + 'line',
                    'type': 'line',
                    'source': 'plume' + percentage,
                    'layout': {},
                    'paint': {
                        'line-dasharray': lineDashArray,
                        'line-width': lineWidth,
                        'line-color': lineColor
                    }
                });
            }
        })
    }

    updateConfidenceIntervalsInView = (checkedValues: any) => {
        this.setState({ confidenceIntervalsInView: checkedValues })
    }

    render() {
        const percentages = ["1%", "5%", "10%", "25%", "50%", "75%", "90%", "95%", "99%"]
        return (
            <div className="Map MajorComponent">
                <div className="MapOutsideContainer">
                    <div id="mapContainer" className="MapContainer" />
                </div>
                <br />
                <h6>Confidence Intervals of Plume Capture Zones</h6>
                <Checkbox.Group
                    options={percentages}
                    defaultValue={["50%", "90%"]}
                    onChange={this.updateConfidenceIntervalsInView}
                />
            </div>
        );
    }
}

export default MLResultsMap;