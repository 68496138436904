import React, { Component } from "react";
import NumericInput from "./NumericInput";

export default class Hydro extends Component {
  constructor(props) {
    super(props);
    let hydroData = {};
    if (this.props.hydroData) {
      hydroData = this.props.hydroData;
    }
    this.state = {
      hydroData: hydroData,
    };
  }

  updateHydroData = (key, value) => {
    let hydroData = this.state.hydroData;
    hydroData[key] = value;
    this.props.updateSiteSpecificData("hydroData", hydroData);
    this.setState({ hydroData: hydroData });
  };

  render() {
    return (
      <div>
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="Predominant GW Flow Direction"
          featureDescription="Predominant Direction of Groundwater flow in degrees east of north (flow from west to east would be 90; from north to south would be 180) [if no gw flow direction is provided EnviMetric will estimate the gw flow direction using surface topography and fit the plume estimates to that flow line]"
          featureString="gwFlowDirection"
          featureUpdateFunction={this.updateHydroData}
          featurePrecision={1}
          featureMin={0}
          featureMax={360}
          data={this.state.hydroData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="Depth to Water"
          featureDescription="Depth to groundwater in feet below groundsurface (all values are negative for consistency with soil profile)"
          featureString="depthToWater"
          featureUpdateFunction={this.updateHydroData}
          featurePrecision={1}
          featureMin={-100000}
          featureMax={0}
          data={this.state.hydroData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="Hydraulic Gradient"
          featureDescription="Hydraulic Gradient (Rise-over-run; unitless; sometimes expressed as 'feet over feet'))"
          featureString="hydraulicGradient"
          featureUpdateFunction={this.updateHydroData}
          featurePrecision={5}
          featureMin={0}
          featureMax={1000000}
          data={this.state.hydroData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="Groundwater Velocity"
          featureDescription="Groundwater Velocity (ft/day)"
          featureString="gwVelocity"
          featureUpdateFunction={this.updateHydroData}
          featurePrecision={4}
          featureMin={0}
          featureMax={1000000}
          data={this.state.hydroData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="Hydraulic Conductivity"
          featureDescription="Hydraulic Conductivity (ft/day)"
          featureString="hydraulicConductivity"
          featureUpdateFunction={this.updateHydroData}
          featurePrecision={8}
          featureMin={0}
          featureMax={1000000}
          data={this.state.hydroData}
        />
        <NumericInput
          readOnly={this.props.readOnly}
          featureName="Hydraulic Transmissivity"
          featureDescription="Hydraulic Transmissivity (ft^2/day)"
          featureString="hydraulicTransmissivity"
          featureUpdateFunction={this.updateHydroData}
          featurePrecision={6}
          featureMin={0}
          featureMax={1000000}
          data={this.state.hydroData}
        />
      </div>
    );
  }
}
