import React from "react";
import { Collapse } from "antd";
import GeneralInfo from "./SiteDataEntrySubComponents/GeneralInfo";
import Contaminants from "./SiteDataEntrySubComponents/Contaminants";
import SoilProfile from "./SiteDataEntrySubComponents/SoilProfile";
import MaxGWConcentrations from "./SiteDataEntrySubComponents/MaxGWConcentrations";
import Hydro from "./SiteDataEntrySubComponents/Hydro";
import GeoChemistry from "./SiteDataEntrySubComponents/GeoChemistry";
import Axios from "axios";
// @ts-ignore
import Loader from "react-loader-spinner";
const firebase = require("firebase");

const { Panel } = Collapse;

interface SiteDataEntryProps {
  userObject: UserObject | null;
  siteData_dict: SiteData_dict;
  toggleView: Function;
  siteNameInView: string | null;
  readOnly: Boolean;
}

interface SiteDataEntryState {
  newSite: Boolean;
  generalSiteInfo: GeneralSiteInfo;
  siteSpecificData: SiteSpecificData | any;
  contaminantInfo: ContaminantInfo | any;
  soilProfile: any;
  maxGWConcentrations: any;
  hydroData: HydroData;
  geoChemistryData: GeoChemistryData;
  saving: Boolean;
}

class SiteDataEntry extends React.Component<
  SiteDataEntryProps,
  SiteDataEntryState
> {
  constructor(props: SiteDataEntryProps) {
    super(props);
    const self = this;
    let newSite = true;
    if (self.props.siteNameInView) {
      if (self.props.siteNameInView in self.props.siteData_dict) {
        newSite = false;
      }
    }
    if (newSite) {
      self.state = {
        newSite: newSite,
        generalSiteInfo: { siteName: self.props.siteNameInView },
        siteSpecificData: {},
        contaminantInfo: {},
        soilProfile: [],
        maxGWConcentrations: [],
        hydroData: {},
        geoChemistryData: {},
        saving: false,
      };
    } else {
      if (this.props.siteNameInView) {
        const siteSpecificData: any =
          self.props.siteData_dict[this.props.siteNameInView].siteSpecificData;
        self.state = {
          newSite: newSite,
          generalSiteInfo: siteSpecificData.generalInfo,
          siteSpecificData: siteSpecificData,
          contaminantInfo: siteSpecificData.contaminantInfo,
          soilProfile: siteSpecificData.soilProfile,
          maxGWConcentrations: siteSpecificData.maxGWConcentrations,
          hydroData: siteSpecificData.hydroData,
          geoChemistryData: siteSpecificData.geoChemistryData,
          saving: false,
        };
      }
    }
  }

  updateSiteSpecificData = (key: siteSpecificDataKey, value: any) => {
    // for updating the state of the this component from the entry sub components
    const self = this;
    let siteSpecificData: SiteSpecificData = self.state.siteSpecificData;
    siteSpecificData[key] = value;
    self.setState({ siteSpecificData: siteSpecificData });
  };

  saveAndReturnToSiteTable = async () => {
    const self = this;
    self.setState({ saving: true });
    const userToken: string = await firebase.auth().currentUser.getIdToken();
    await Axios.post(
      "https://us-central1-envimetric.cloudfunctions.net/postSiteData_0_1_0?userToken=" +
        userToken +
        "&siteName=" +
        this.state.siteSpecificData.generalInfo.siteName,
      this.state.siteSpecificData
    );
    self.props.toggleView();
  };

  saveAndReturnToSiteTable_wrapper = () => {
    if (!this.state.siteSpecificData.generalInfo.siteName) {
      // TODO: check the backend to make sure it is unique
      alert("A unique site name is needed to continue");
    } else {
      this.saveAndReturnToSiteTable();
    }
  };

  render() {
    let siteName: string | null = this.state.generalSiteInfo.siteName;
    if (!siteName) {
      siteName = this.state.siteSpecificData.siteName;
    }
    if (this.state.saving) {
      return (
        <div className="SiteDataEntry">
          <Loader
            type="Audio" //"ThreeDots"
            color="rgb(0, 217, 255)"
            height={100}
            width={100}
          />
        </div>
      );
    } else {
      return (
        <div className="SiteDataEntry">
          <h4>{siteName} - Site Data Entry </h4>
          <SiteDataEntryTopButtons
            saveAndReturnToSiteTable={this.saveAndReturnToSiteTable}
            returnToSiteTable={this.props.toggleView}
            readOnly={this.props.readOnly}
          />

          <Collapse
            defaultActiveKey={[
              "generalInfo",
              "contaminantTypes",
              "soilProfile",
              "maxGWConcentrations",
              "hydro",
              "geo",
            ]}
          >
            <Panel header="General Info" key="generalInfo">
              <GeneralInfo
                generalSiteInfo={this.state.generalSiteInfo}
                updateSiteSpecificData={this.updateSiteSpecificData}
                readOnly={this.props.readOnly}
              />
            </Panel>

            <Panel header="Contaminant Types" key="contaminantTypes">
              <Contaminants
                contaminantInfo={this.state.contaminantInfo}
                updateSiteSpecificData={this.updateSiteSpecificData}
                readOnly={this.props.readOnly}
              />
            </Panel>

            <Panel header="Soil Profile" key="soilProfile">
              <SoilProfile
                soilProfile={this.state.soilProfile}
                updateSiteSpecificData={this.updateSiteSpecificData}
                readOnly={this.props.readOnly}
              />
            </Panel>

            <Panel header="Max GW Concentrations" key="maxGWConcentrations">
              <MaxGWConcentrations
                maxGWConcentrations={this.state.maxGWConcentrations}
                updateSiteSpecificData={this.updateSiteSpecificData}
                readOnly={this.props.readOnly}
              />
            </Panel>

            <Panel header="Hydrogeology Data" key="hydro">
              <Hydro
                hydroData={this.state.hydroData}
                updateSiteSpecificData={this.updateSiteSpecificData}
                readOnly={this.props.readOnly}
              />
            </Panel>

            <Panel header="GeoChemistry Data" key="geo">
              <GeoChemistry
                geoChemistryData={this.state.geoChemistryData}
                updateSiteSpecificData={this.updateSiteSpecificData}
                readOnly={this.props.readOnly}
              />
            </Panel>
          </Collapse>
        </div>
      );
    }
  }
}

export default SiteDataEntry;

function SiteDataEntryTopButtons(props: any) {
  if (props.readOnly) {
    return (
      <h3>
        <button
          className="btn btn-secondary"
          onClick={() => props.returnToSiteTable()}
        >
          return to site table
        </button>
      </h3>
    );
  } else {
    return (
      <h3>
        <button
          className="btn btn-primary"
          onClick={() => props.saveAndReturnToSiteTable()}
        >
          save and return to site table
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => props.returnToSiteTable()}
        >
          cancel and return to site table
        </button>
      </h3>
    );
  }
}
