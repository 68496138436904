import React, { Component } from "react";
import { Radio, Checkbox } from "antd";

export default class Contaminants extends Component {
  constructor(props) {
    super(props);

    let mainContaminantType = null;
    let contaminantsPresent = [];
    if (props.contaminantInfo) {
      if (props.contaminantInfo.mainContaminantType) {
        mainContaminantType = props.contaminantInfo.mainContaminantType;
      }
    }

    if (props.contaminantInfo) {
      if (props.contaminantInfo.contaminantsPresent) {
        contaminantsPresent = props.contaminantInfo.contaminantsPresent;
      }
    }

    this.state = {
      mainContaminantType: mainContaminantType,
      contaminantsPresent: contaminantsPresent,
    };
  }

  onMainContaminantChange = (e) => {
    const contaminantInfo = {
      mainContaminantType: e.target.value,
      contaminantsPresent: this.state.contaminantsPresent,
    };
    this.props.updateSiteSpecificData("contaminantInfo", contaminantInfo);
    this.setState({
      mainContaminantType: e.target.value,
    });
  };

  onContaminantsPresentChange = (checkedValues) => {
    const contaminantInfo = {
      mainContaminantType: this.state.mainContaminantType,
      contaminantsPresent: checkedValues,
    };
    this.props.updateSiteSpecificData("contaminantInfo", contaminantInfo);
    this.setState({
      contaminantsPresent: checkedValues,
    });
  };

  render() {
    const specificContaminantTypeOptions = [
      "Gasoline",
      "Diesel",
      "Kerosene",
      "HeatingOil",
      "CoalTar",
      "Benzene",
      "MTBE",
      "TCE",
      "PCE",
      "DCE",
      "VC",
      "Dichlorobenzene_1_4",
      "Lead",
      "Chromium",
      "Arsenic",
      "Cadmium",
      "Mercury",
      "Pesticides",
      "Herbicides",
      "Nitrate",
      "Munitions",
      "PFOA",
      "PFOS",
    ];
    return (
      <div>
        <h4>Main Contaminant Type:</h4>
        <Radio.Group
          onChange={this.onMainContaminantChange}
          value={this.state.mainContaminantType}
          disabled={this.props.readOnly}
        >
          <Radio value={"LNAPL"}>LNAPL</Radio>
          <Radio value={"DNAPL"}>DNAPL</Radio>
          <Radio value={"Metals"}>Metals</Radio>
          <Radio value={"Agri"}>Agri</Radio>
          <Radio value={"Munitions"}>Munitions</Radio>
          <Radio value={"PFAS"}>PFAS</Radio>
          <Radio value={"Other"}>Other</Radio>
        </Radio.Group>

        <br />
        <h4>Contaminants Present:</h4>
        <Checkbox.Group
          options={specificContaminantTypeOptions}
          defaultValue={this.state.contaminantsPresent}
          onChange={this.onContaminantsPresentChange}
          disabled={this.props.readOnly}
        />
      </div>
    );
  }
}
