import React, { Component } from "react";
import { InputNumber } from "antd";
import SiteSpecificMap from "./SiteSpecificMap"

export default class GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteName: props.generalSiteInfo.siteName,
      lat: props.generalSiteInfo.lat,
      lon: props.generalSiteInfo.lon,
    };
  }

  updateSiteName = (event) => {
    const siteSpecificData = {
      siteName: event.target.value,
      lat: this.state.lat,
      lon: this.state.lon,
    };
    this.props.updateSiteSpecificData("generalInfo", siteSpecificData);
    this.setState({ siteName: event.target.value });
  };

  updateLat = (value) => {
    const siteSpecificData = {
      siteName: this.state.siteName,
      lat: value,
      lon: this.state.lon,
    };
    this.props.updateSiteSpecificData("generalInfo", siteSpecificData);
    this.setState({ lat: value });
  };

  updateLon = (value) => {
    const siteSpecificData = {
      siteName: this.state.siteName,
      lat: this.state.lat,
      lon: value,
    };
    this.props.updateSiteSpecificData("generalInfo", siteSpecificData);
    this.setState({ lon: value });
  };

  render() {
    return (
      <div className="siteDataEntrySubComponent">

        <div>
          <SiteSpecificMap sourceLat={this.state.lat} sourceLon={this.state.lon} updateLat={this.updateLat} updateLon={this.updateLon} readOnly={this.props.readOnly} />
        </div>
        <br />
        <div>
          <InputNumber
            readOnly={this.props.readOnly}
            value={this.state.lat}
            precision={6}
            onChange={this.updateLat}
            min={-90} max={90}
            step={0.001}
          />

          <h6>Source Area Latitude</h6>
          <InputNumber
            readOnly={this.props.readOnly}
            value={this.state.lon}
            precision={6}
            onChange={this.updateLon}
            min={-180} max={180}
            step={0.001}
          />
          <h6>Source Area Longitude</h6>
        </div>

      </div>
    );
  }
}
