import React from "react";

interface Props {
  userObject: UserObject | null;
  userSignOut: Function;
  returnToMainPage: Function | null;
}

function Header(props: Props) {
  let evaluationsRemaining: number = 0;
  //@ts-ignore
  if (props.userObject) {
    if (
      "evaluationsConducted_ML" in props.userObject &&
      "evaluationsPurchased_ML" in props.userObject
    ) {
      // prettier-ignore
      //@ts-ignore
      evaluationsRemaining = props.userObject.evaluationsPurchased_ML - props.userObject.evaluationsConducted_ML;
    }
  }
  return (
    <div className="Header">
      <nav className="navbar navbar-light bg-light">
        {props.returnToMainPage ? (
          //@ts-ignore
          // eslint-disable-next-line
          <a onClick={() => props.returnToMainPage()}>
            <div className="navbar-brand" id="navbar-brand">
              <img
                src="/images/logo.jpg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt=""
              ></img>{" "}
              EnviMetric
            </div>
          </a>
        ) : (
          // eslint-disable-next-line
          <a>
            <div className="navbar-brand" id="navbar-brand">
              <img
                src="/images/logo.jpg"
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt=""
              ></img>{" "}
              EnviMetric
            </div>
          </a>
        )}

        <ul className="navbar-nav ml-auto"></ul>

        <a
          className="nav-link"
          href="https://www.youtube.com/watch?v=pnKy64vrKmc"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          Help Video
        </a>

        {props.userObject ? (
          <div>
            <a
              className="nav-link dropdown-toggle settings-dropdown"
              href="./"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {props.userObject.email}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <div>
                Evaluations Available:{" "}
                <span className="evalsAvailable">{evaluationsRemaining}</span>
                {evaluationsRemaining === 0 ? (
                  <div>
                    contact ryan.velazquez@azimuth1.com to purchase additional
                    evaluation credits
                  </div>
                ) : null}
              </div>
              <button onClick={() => props.userSignOut()}>signout</button>
            </div>
          </div>
        ) : null}
      </nav>
    </div>
  );
}

export default Header;
