import React, { Component } from "react";

import { InputNumber, Switch } from "antd";

export default class NumericInput extends Component {
  constructor(props) {
    super(props);
    var featureKnown = false;
    if (this.props.data.hasOwnProperty(this.props.featureString)) {
      if (this.props.data[this.props.featureString] !== "unknown") {
        featureKnown = true;
      }
    }
    if (featureKnown) {
      this.state = {
        featureKnown: true,
        featureValue: this.props.data[this.props.featureString],
      };
    } else {
      this.state = {
        featureKnown: false,
        featureValue: "unknown",
      };
    }
  }

  updateFeature = (value) => {
    this.props.featureUpdateFunction(this.props.featureString, value);
    this.setState({ featureValue: value });
  };

  toggleFeatureKnown = () => {
    var nextFeatureKnown;
    this.state.featureKnown ? (nextFeatureKnown = false) : (nextFeatureKnown = true);
    let featureValue;
    if (nextFeatureKnown === false) {
      featureValue = "unknown";
      this.props.featureUpdateFunction(this.props.featureString, "unknown");
    } else {
      if (this.state.featureValue === "unknown") {
        featureValue = 0;
      } else {
        featureValue = this.state.featureValue;
      }
      this.props.featureUpdateFunction(this.props.featureString, featureValue);
    }
    this.setState({
      featureKnown: nextFeatureKnown,
      featureValue: featureValue,
    });
  };

  render() {
    return (
      <div style={{ marginTop: "15px" }}>
        <span>
          {" "}
          {this.props.featureName} {this.props.estimateText ? this.props.estimateText : "Known?"}{" "}
        </span>
        {this.props.readOnly ? null : <Switch checked={this.state.featureKnown} onChange={this.toggleFeatureKnown} />}
        <br />
        <InputNumber
          readOnly={this.props.readOnly}
          disabled={!this.state.featureKnown}
          defaultValue={0}
          value={this.state.featureValue}
          precision={this.props.featurePrecision}
          onChange={this.updateFeature}
          min={this.props.featureMin}
          max={this.props.featureMax}
        />
        <span> {this.props.featureDescription} </span>
        <br />
      </div>
    );
  }
}
